import { useNavigate, useParams } from "react-router";
import { AnswerCompare } from "../components/quiz/answer-compare";
type PageProps = {
    questionId: number    
}
export default function SingleResultPage() {
    const params = useParams<keyof PageProps>()
    const navigate = useNavigate();
    let questionId = 1;

    if(!params.questionId){
        navigate("/quiz");
    }
    else{
        questionId = parseInt(params.questionId);
    }
    return (
        <AnswerCompare questionId={questionId}></AnswerCompare>
    );
}
