import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/error-page';
import HomePage from './pages/home-page';
import QuizPage from './pages/quiz-page';
import QuestionPage from './pages/question-page';
import QuizPageLayout from './pages/quiz-page-layout';
import ResultsPage from './pages/results-page';
import ResultsPageLayout from './pages/results-page-layout';
import SingleResultPage from './pages/single-result-page';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "quiz", element: <QuizPageLayout />, children: [
        { index: true, element: <QuizPage/> },
        { path: "results", element: <ResultsPageLayout/>, children: [
          { index: true, element: <ResultsPage/> },
          { path: ":questionId", element: <SingleResultPage/> }
        ] },
        { path: ":questionId", element: <QuestionPage/> },

      ] },


      // ToDo
      // {
      //   path: "/info",
      //   element: <InfoPage></InfoPage>
      // },
      // {
      //   path: "/kontakt",
      //   element: <Contact></Contact>
      // },
    ],
  },
]);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
   <RouterProvider router={router}></RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
