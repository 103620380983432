
import { QuizStoreProvider } from "../store/quizStoreProvider";
import { MaxWidthWrapper } from "../components/max-width-wrapper";

import { Outlet } from "react-router";

export default function QuizPageLayout() {

    return (
      <QuizStoreProvider>
        <MaxWidthWrapper className="mb-12 mt-24 sm:mt:40 flex flex-col items-center justify-center">
          <Outlet/>
        </MaxWidthWrapper>
      </QuizStoreProvider>
    );
}
