import { QUESTIONS_COUNT } from "../../questions";

export const Instructions = () => {
  return (
    <div className="py-6 ml-5 lg:m-0">
      <ul className="list-decimal text-lg md:text-2xl lg:text-3xl">
        <li>
          Odpowiedz na {QUESTIONS_COUNT} stwierdzeń zgodnie ze swoimi poglądami.
        </li>
        <li>
          Po zebraniu odpowiedzi zobaczysz paski ze zgodością Twoich odpowiedzi
          z odpowiedziami kandydatów.
        </li>
        <li>
          Porównaj swoje odpowiedzi z kandydatami i przeczytaj komentarze
          wyjaśniające ich wybór.
        </li>
      </ul>
    </div>
  );
}