"use client"

import { Link } from "react-router-dom";
import { PostedAnswer } from "../../models/posted-answer";
import { StoredQuestion } from "../../models/question";
import { MaxWidthWrapper } from "../max-width-wrapper";
import { cn, isAllAnswered } from "../../lib/utils";
import { Button, buttonVariants } from "../ui/button";
import { UI_CONSTANTS } from "../../ui-consts";
import { QUESTIONS } from "../../questions";

export const QuestionPreviewer = ({questionId, action, answers}: {questionId : number, action: string, answers: PostedAnswer[]}) => {
    const questions: StoredQuestion[] = QUESTIONS as StoredQuestion[];
    const classes = "uppercase m-2";

    function isQustionAnswered(id: number){        
      let storedAnswer = answers.find(a => a.id === id);        
      const result = storedAnswer && storedAnswer.answer != null;
      return result;
  }

    return (
      <MaxWidthWrapper className="text-center">
        {questions.map((q) => {
          if (q.id === questionId) {
            return (
              <Link
                to={`${action}${q.id}`}
                key={q.id}
                className={cn(
                  classes,
                  buttonVariants({
                    variant: "default",
                    size: "default",
                  })
                )}
              >
                {q.id}
              </Link>
            );
          }
          if (isQustionAnswered(q.id)) {
            return (
              <Link
                to={`${action}${q.id}`}
                key={q.id}
                className={cn(
                  classes,
                  buttonVariants({
                    variant: "outline",
                    size: "default",
                  })
                )}
              >
                {q.id}
              </Link>
            );
          } 
          else {
            return (
              <Link
              to={`${action}${q.id}`}
              key={q.id}
              className={cn(
                classes,
                buttonVariants({
                  variant: "ghost",
                  size: "default",
                })
              )}
            >
              {q.id}
            </Link>
            );
          }
         })}   
        {isAllAnswered(answers) ? (
          <Link
            to={`/quiz/results`}
            key="result"
            className={
            cn(
              classes,
              buttonVariants({
                variant: "outline",
                size: "default",
              }))}
          >
            {UI_CONSTANTS.quiz.results}
          </Link>
        ) : (
          <></>
        )}      
      </MaxWidthWrapper>
    );
}