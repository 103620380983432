import { createSlice } from "@reduxjs/toolkit";
import { PostedAnswer } from "../../models/posted-answer";
import { QUESTIONS } from "../../questions";

export interface AnswersState {
     answers: PostedAnswer[],
     startTime: number,
     endTime: number | null,
}

function getAnswersTemplate(): PostedAnswer[] {
    return QUESTIONS.map( q => ({id: q.id, answer: null}))
}

const initialState =  { answers: getAnswersTemplate(), startTime: Date.now(), endTime: null } as AnswersState;

export const answersSlice = createSlice({
    name: "answers",
    initialState: initialState,
    reducers: {
        hydrate:(state, action) => {
            return action.payload;
            },
        add: (state, action) => {
            if(state.answers === undefined) {
                var newState = {...initialState};
                newState.answers = newState.answers.map((item) => {
                    if(item.id !== action.payload.id){
                        return item;
                    }
                    return {...item, ...action.payload }
                });
                return newState;
            }
            state.answers = state.answers.map((item) => {
                if(item.id !== action.payload.id){
                    return item;
                }
                return {...item, ...action.payload }
            })
        },
        close: (state, action) => {
            state.endTime = Date.now();
            return state;
        },
        clear: (state) => {
            return initialState;
        }
    }
});

export const { add, clear, hydrate, close } = answersSlice.actions;
export default answersSlice.reducer;