import { QUESTIONS_COUNT } from "./questions";

export const UI_CONSTANTS = {

    landingPage: {
        header: "Wybierz mądrze, na podstawie",
        headerColour: " swoich poglądów.",
        headerDescription: "Udziel odpowiedzi na " + QUESTIONS_COUNT +" stwierdzeń i zobacz do kogo jest Ci najbliżej w tych wyborach."
    },
    startQuiz: "zacznij ankietę",
    navbar:{
        about: "O projekcie",
        faq:"FAQ",
        contact:"kontakt",
    },
    quiz: {
        quizWithNo: "Pytanie: ",
        results: "Wyniki",
        compareAnswers: "Porównaj odpowiedzi",
        seeComitteeAnswers: "Zobacz odpowiedzi komitetów",
        yourAnswer:"Twoja odpowiedź"
    },
    agree: "Zgadzam się",
    disagree: "Nie zgadzam się",
    no_opinion: "Nie mam zdania",
}