import { MaxWidthWrapper } from "./max-width-wrapper";

export const Contact = () => {

    return (
      <div className="bg-primary text-md md:text-xl lg:text-2xl text-white" id="contact">
        <MaxWidthWrapper className="py-5 md:py-10 lg:py-20">
          <p>Kontakt:</p>
          <span>kontakt@latarnik.olsztyn.pl</span>
        </MaxWidthWrapper>
      </div>
    );
}