import { MaxWidthWrapper } from "./max-width-wrapper";
import { buttonVariants } from "./ui/button";
import { NavbarButton } from "./navbar-button";
import { cn } from "../lib/utils";
import { UI_CONSTANTS } from "../ui-consts";
import { Link } from "react-router-dom";


export const Navbar = () => {
    return (
      <nav className="sticky h-14 inset-x-0 top-0 z-30 w-full border-b border-gray-200 bg-white/75 backdrop-blur-lg transition-all">
        <MaxWidthWrapper>
          <div className="flex h-14 items-center justify-between border-b border-zinc-200">
            <Link to="/" className="flex z-40 font-semibold">
              <span>Latarnik</span>
            </Link>
            {/* todo: add mobile navbar */}
            <div className="hidden items-center space-x-4 sm:flex">
              <>
                <NavbarButton
                  url="#"
                  text={UI_CONSTANTS.navbar.about}
                ></NavbarButton>
                <NavbarButton
                  url="#"
                  text={UI_CONSTANTS.navbar.faq}
                ></NavbarButton>
                <NavbarButton
                  url="#contact"
                  text={UI_CONSTANTS.navbar.contact}
                ></NavbarButton>
                <Link
                  to="/quiz"
                  className={cn(
                    "uppercase",
                    buttonVariants({
                      variant: "outline",
                      size: "sm",
                    })
                  )}
                >
                  {UI_CONSTANTS.startQuiz}
                </Link>
              </>
            </div>
          </div>
        </MaxWidthWrapper>
      </nav>
    );
}