import { Check } from "lucide-react"

export const AnswerCheck = () => {
    return (
            <Check
              color="hsl(var(--primary))"
              size={24}
              className="inline animate-bounce"
            />
    );
}