"use client";

import { store } from "./latarnikStore"
import { Provider, useDispatch } from "react-redux"
import { hydrate } from "./slices/answersSlice";
import { useEffect } from "react";
import { PostedAnswer } from "../models/posted-answer";

export const QuizStoreProvider = ({ children } : { children: React.ReactNode } ) =>{
    return (
      <Provider store={store}>
        <QuizStoreManager>
          {children}
        </QuizStoreManager>
      </Provider>
    );
}

export const QuizStoreManager = ({ children } : { children: React.ReactNode } ) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      let answers: PostedAnswer[] = [];
      const persistedState = localStorage.getItem("answers");
      if (persistedState) answers = JSON.parse(persistedState);
      dispatch(hydrate(answers))
      store.subscribe(()=>{
        localStorage.setItem('answers', JSON.stringify(store.getState().answer))
      })
    }
  }, [dispatch])
  return <>
    {children}
    </>
}