import { StartQuiz } from "../components/quiz/start-quiz";
import { Button } from "../components/ui/button";
import { UI_CONSTANTS } from "../ui-consts";
import { useDispatch } from "react-redux";
import { clear } from "../store/slices/answersSlice";
import {  useNavigate } from "react-router";

export default function QuizPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
    const onStart =() => {
        localStorage.removeItem("answers");
        dispatch(clear())
        navigate("/quiz/1");
      }

  return (
    <StartQuiz>
    <Button
      variant="default"
      size="xl"
      onClick={() => onStart()}
      className="uppercase text-lg"
    >
      {UI_CONSTANTS.startQuiz}
    </Button>
  </StartQuiz>
  );
}
