
import { Link } from 'react-router-dom'
import { buttonVariants } from "./ui/button";
import { cn } from "../lib/utils";

export const NavbarButton = ({text, url, className}:{text:string,url:string, className?:string}) =>{

    return(
        <Link
        to={url}
        className={cn("uppercase",
        cn(className,buttonVariants({
            variant: 'link',
            size: 'sm'
        })))}>{ text }</Link>
    );
}