import { useDispatch } from "react-redux";
import { add } from "../../store/slices/answersSlice";
import { Button } from "../ui/button";
import { useNavigate } from "react-router";

export const VotingButton = ({
  questionId,
  voteValue,
  text,
  isActive,
  action,
}: {
  questionId: number
  voteValue: number;
  text: string;
  isActive: boolean;
  action:string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let savedVote: null | number | undefined = null;

  const vote = (value: number) => {
    if (isActive) {
      dispatch(add({ id: questionId, answer: value }));
      navigate(action);
    }
  };

    const getButtonClass = (voteValue: number) => {
        if (savedVote !== undefined && savedVote === voteValue) {
          return "default";
        }
      
      return "secondary";
    
    };

    return (
      <Button
        variant={getButtonClass(voteValue)}
        onClick={() => vote(voteValue)}
        size="xl"
        className="text-lg"
      >
        {text}
      </Button>
    );
  
};