import { close } from "../store/slices/answersSlice"
import { Button } from "../components/ui/button";
import { UI_CONSTANTS } from "../ui-consts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CommitteeResults } from "../components/quiz/committee-results";
import { useAnswerSelector } from "../store/latarnikStore";
import { calculateResult } from "../lib/results_calculator";
import { useEffect, useState } from "react";
import { isAllAnswered, submitAnserAsync } from "../lib/utils";


export default function ResultsPage() {
  const answers = useAnswerSelector((state) => state.answer.answers);
  const isClosed = useAnswerSelector((state) => state.answer.endTime != null);
  const score = calculateResult(answers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isAllAnswered(answers) && !isClosed && !initialized) {
      setInitialized(true);
      dispatch(close(true));
      const submitAnswers = async () => {
        const submitObject = {
          ip: "",
          a1: answers.find((a) => a.id === 1)?.answer,
          a2: answers.find((a) => a.id === 2)?.answer,
          a3: answers.find((a) => a.id === 3)?.answer,
          a4: answers.find((a) => a.id === 4)?.answer,
          a5: answers.find((a) => a.id === 5)?.answer,
          a6: answers.find((a) => a.id === 6)?.answer,
          a7: answers.find((a) => a.id === 7)?.answer,
          a8: answers.find((a) => a.id === 8)?.answer,
          a9: answers.find((a) => a.id === 9)?.answer,
          a10: answers.find((a) => a.id === 10)?.answer,
          a11: answers.find((a) => a.id === 11)?.answer,
          a12: answers.find((a) => a.id === 12)?.answer,
          a13: answers.find((a) => a.id === 13)?.answer,
          a14: answers.find((a) => a.id === 14)?.answer,
          a15: answers.find((a) => a.id === 15)?.answer,
          a16: answers.find((a) => a.id === 16)?.answer,
          a17: answers.find((a) => a.id === 17)?.answer,
          a18: answers.find((a) => a.id === 18)?.answer,
          a19: answers.find((a) => a.id === 19)?.answer,
          a20: answers.find((a) => a.id === 20)?.answer,
          c1: score.find((s) => s.committeeId === 1)?.score,
          c2: score.find((s) => s.committeeId === 2)?.score,
          c3: score.find((s) => s.committeeId === 3)?.score,
        };
        const apiURL = process.env.REACT_APP_API_URL!
        await submitAnserAsync(submitObject, apiURL);
      };

      submitAnswers()
        .catch(console.error);
    }
  }, [answers, isClosed, dispatch, initialized, score]);



  return (
    <>
      <CommitteeResults quizScore={score}></CommitteeResults>
      <div className="mb-12 mt-24 sm:mt:40 flex flex-col items-center justify-center">
        <Button
          variant="default"
          size="xl"
          onClick={() => navigate("/quiz/results/1")}
          className="text-lg"
        >
          {UI_CONSTANTS.quiz.compareAnswers}
        </Button>
      </div>
    </>
  );
}
