import { QuizScore } from "../../models/quiz-score";
import { SingleComitteeResult } from "./single-commitee-result";

export const CommitteeResults = ({quizScore}:{quizScore:QuizScore[]}) => {

    return (
      <>
        {quizScore.map((quizScore) => (
          <SingleComitteeResult
            score={quizScore.score}
            committeeId={quizScore.committeeId}
            key={quizScore.committeeId}
          ></SingleComitteeResult>
        ))}
      </>
    );

}