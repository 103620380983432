import { Progress } from "../../components/ui/progress";

export const SingleComitteeResult = ({committeeId, score}: {committeeId: number, score:number}) => {

    return(
        <div className="flex items-center gap-x-4 my-4">
        <div>{committeeId}</div>
              <Progress indicatorColor={`committee-${committeeId}-main-color`} value={score} height="h-10"></Progress> {score}%
        </div>
    );

}