import { useEffect } from 'react';
import './App.css';
import { Outlet } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import '@radix-ui/themes/styles.css';

function App() {

useEffect(()=>{
  const stamp = localStorage.getItem("stamp");
  if(!stamp){
    const newUuid = uuidv4();
    localStorage.setItem("stamp", newUuid);
  }

},[])

  return <Outlet />;
}

export default App;