export const QUESTIONS = [
    {"question": "What is the capital of France?", "id": 1},
    {"question": "How many planets are there in our solar system?", "id": 2},
    {"question": "What is the largest mammal on Earth?", "id": 3},
    {"question": "Who wrote 'Romeo and Juliet'?", "id": 4},
    {"question": "What is the main ingredient in guacamole?", "id": 5},
    {"question": "How tall is the Eiffel Tower?", "id": 6},
    {"question": "What is the currency of Japan?", "id": 7},
    {"question": "Who discovered penicillin?", "id": 8},
    {"question": "What is the capital of Australia?", "id": 9},
    {"question": "How many continents are there?", "id": 10},
    {"question": "Who painted the Mona Lisa?", "id": 11},
    {"question": "What is the boiling point of water?", "id": 12},
    {"question": "Who invented the telephone?", "id": 13},
    {"question": "What is the speed of light?", "id": 14},
    {"question": "How many players are on a soccer team?", "id": 15},
    {"question": "What is the currency of Brazil?", "id": 16},
    {"question": "Who is the author of 'To Kill a Mockingbird'?", "id": 17},
    {"question": "What is the largest ocean on Earth?", "id": 18},
    {"question": "How many bones are in the human body?", "id": 19},
    {"question": "What is the capital of China?", "id": 20}
  ]

  export const QUESTIONS_COUNT = QUESTIONS.length;