import { VotingButton } from "./voting-button";
import { UI_CONSTANTS } from "../../ui-consts";

export const VotingButtons = ({questionId, action, isActive} : { questionId: number, action: string, isActive: boolean}) => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-10 ">
      <VotingButton
        questionId={questionId}
        text={UI_CONSTANTS.agree}
        voteValue={1}
        isActive={isActive}
        action={action}
      ></VotingButton>
      <VotingButton
        questionId={questionId}
        text={UI_CONSTANTS.no_opinion}
        voteValue={0}
        isActive={isActive}
        action={action}
      ></VotingButton>
      <VotingButton
        questionId={questionId}
        text={UI_CONSTANTS.disagree}
        voteValue={-1}
        isActive={isActive}
        action={action}
      ></VotingButton>
    </div>
  );
}