import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { PostedAnswer } from "../models/posted-answer";
import axios from "axios";
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isAllAnswered (answers: PostedAnswer[]) {
  return !answers.find(x => x.answer == null);
}

export async function submitAnserAsync(submitObject: any, apiUrl: string){
  let ipAddress = localStorage.getItem("stamp");
  submitObject.ip = ipAddress;

  const response = await axios.post(apiUrl + "/api/quiz", submitObject);
}