import { UI_CONSTANTS } from "../../ui-consts"
import { AnswerCheck } from "./answer-check"
import { TooltipComponent } from "./tooltip"

export const UserAnswerTableMobile = ({storedAnswer, printAnswerFn } : {storedAnswer:number, printAnswerFn: (a:number)=>JSX.Element[]}) => {
    return (
      <div className="block lg:hidden">
      <div className="grid lg:grid-cols-1 gap-5 m-5 text-center">
        <div className="">
          {UI_CONSTANTS.agree}
          {storedAnswer === 1 ? (
            <TooltipComponent
              description={UI_CONSTANTS.quiz.yourAnswer}
            >
              <AnswerCheck />
            </TooltipComponent>
          ) : null}
        </div>
        <div className="border-gray-700 border p-10">
          {printAnswerFn(1)}
        </div>
        <div className="">
          {UI_CONSTANTS.no_opinion}
          {storedAnswer === 0 ? (
            <TooltipComponent
              description={UI_CONSTANTS.quiz.yourAnswer}
            >
              <AnswerCheck />
            </TooltipComponent>
          ) : null}
        </div>
        <div className="border-gray-700 border p-10">
          {printAnswerFn(0)}
        </div>
        <div className="">
          {UI_CONSTANTS.disagree}
          {storedAnswer === -1 ? (
            <TooltipComponent
              description={UI_CONSTANTS.quiz.yourAnswer}
            >
              <AnswerCheck />
            </TooltipComponent>
          ) : null}
        </div>
        <div className="border-gray-700 border p-10">
          {printAnswerFn(-1)}
        </div>
      </div>
    </div>
    )
}