
import { QuizStoreProvider } from "../store/quizStoreProvider";

import { Outlet } from "react-router";

export default function ResultsPageLayout() {

    return (
      <QuizStoreProvider>
          <Outlet/>
      </QuizStoreProvider>
    );
}
