import { QuestionPreviewer } from "./question-previewer";
import { useEffect, useState } from "react";
import { Statement } from "./statement";
import { MaxWidthWrapper } from "../max-width-wrapper";
import { useAnswerSelector } from "../../store/latarnikStore";
import { PostedAnswer } from "../../models/posted-answer";
import { useNavigate } from "react-router";
import { VotingButtons } from "./voting-buttons";

interface QuestionProps {
  questionId: number;
  text: string
}

export const Question = ({ questionId, text }: QuestionProps) => {
  const [isActive, setIsActive] = useState(false);
  const answerSlice = useAnswerSelector((state) => state.answer);

  const [answers, setAnswers] = useState<PostedAnswer[]>([])
  const [action, setAction] = useState("results");
  const navigate = useNavigate();

  useEffect(() => {
      const timer = setTimeout(() => setIsActive(true), 500);
      if(answerSlice !== undefined && answerSlice.answers){
        let clonedAnswers = [...answerSlice.answers];
        setAnswers(clonedAnswers);
        const idParsed = parseInt(questionId.toString());
        const answerCandidate = clonedAnswers.find(a => a.id !== idParsed && a.answer === null);
        if(answerCandidate === undefined){
          setAction(`/quiz/results`)
        }
        else{
          setAction(`/quiz/${answerCandidate.id}`);
        }
      }
      else{
        navigate("/quiz")
      }
      return () => clearTimeout(timer);
    }, [questionId, answerSlice, navigate]);

  return (
    <MaxWidthWrapper className="mb-12 flex flex-col items-center justify-center fadeIn">
          <div className="py-20">
            <Statement id={questionId} text={text}></Statement>
            <VotingButtons questionId={questionId} action={action} isActive={isActive}/>
          </div>
          { <QuestionPreviewer
            questionId={questionId}
            answers={answers}
            action="/quiz/"
          ></QuestionPreviewer> }
    </MaxWidthWrapper>
  );
};
