import { useNavigate, useParams } from "react-router";
import { QUESTIONS, QUESTIONS_COUNT } from "../questions";
import { Question } from "../components/quiz/question";
type PageProps = {
      questionId: number    
  }

export default function QuestionPage () {
    const navigate = useNavigate();
    const params = useParams<keyof PageProps>()
    let questionId = 1;

    if(!params.questionId){
        navigate("/quiz");
    }
    else{
        questionId = parseInt(params.questionId);
    }

    if(questionId > QUESTIONS_COUNT)
      {
        navigate("/quiz/results");
        return <></>
      }
      else{
        return (
          <Question
            questionId={questionId}
            text={QUESTIONS[questionId - 1].question}
          ></Question>
        );
      }
    
}