import { QuestionPreviewer } from "./question-previewer";
import { Statement } from "./statement";
import { useEffect, useState } from "react";
import { useAnswerSelector } from "../../store/latarnikStore";
import { PostedAnswer } from "../../models/posted-answer";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@radix-ui/react-hover-card";
import { CommitteAnswer } from "../../models/committee-answer";
import { COMMITEE_ANSWERS } from "../../answers";
import { QUESTIONS } from "../../questions";
import { useNavigate } from "react-router";
import { MessageCircleMore } from "lucide-react";
import { UserAnswerTable } from "./user-answer-table";
import { UserAnswerTableMobile } from "./user-answer-table-mobile";


interface AnswerCompareProps {
    questionId: number,
  }

export const AnswerCompare = ({questionId}:AnswerCompareProps) => {
    const answerSlice = useAnswerSelector((state) => state.answer);
    const [answers, setAnswers] = useState<PostedAnswer[]>([])
    const [storedAnswer, setStoredAnswer] = useState<number | null | undefined>(null);    
    const navigate = useNavigate();
    useEffect(() => {
      if(answerSlice !== undefined && answerSlice.answers){
        setAnswers([...answerSlice.answers]);
        setStoredAnswer(answers.find(a => a.id === questionId)?.answer);  
      }
      else{
        navigate("/quiz")
      }
    }, [questionId, answerSlice, navigate, answers]);

    const committeesAnswers: CommitteAnswer[] = [];
    COMMITEE_ANSWERS.forEach(ca=> {
      const answer = ca.answers.find(a=>a.id === questionId);
      if(answer){
        committeesAnswers.push({...answer, committeeName: ca.commiteeId});
      }
    })

    const printCommitteeAnswer = (expectedAnswer: number) => {
      const answers = committeesAnswers.filter(a=> a.answer === expectedAnswer);
      return answers.map(a => { return (
        <HoverCard key={a.committeeName}>
          <HoverCardTrigger> <div>{a.committeeName} <MessageCircleMore strokeWidth={1} style={{display:"inline"}} /></div></HoverCardTrigger>
          <HoverCardContent>{a.comment}</HoverCardContent>
        </HoverCard>
      );
      })
    }

    return (
      <>
        {questionId && storedAnswer ? (
          <>
            <Statement
              id={questionId}
              text={QUESTIONS.find((q) => q.id === questionId)?.question!}
            ></Statement>
            <UserAnswerTable storedAnswer={storedAnswer} printAnswerFn={printCommitteeAnswer} />
            <UserAnswerTableMobile storedAnswer={storedAnswer} printAnswerFn={printCommitteeAnswer} />

            <QuestionPreviewer
              questionId={questionId}
              answers={answers}
              action="/quiz/results/"
            ></QuestionPreviewer>
          </>
        ) : (
          <></>
        )}
      </>
    );
}