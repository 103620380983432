import { MaxWidthWrapper } from "./max-width-wrapper";

export const Footer = () => {
    return(
        <footer className="relative w-full h-12">
            <MaxWidthWrapper className="flex flex-col items-center justify-center py-3">
                <p>
                © Copyright 2024. Wszelkie prawa zastrzeżone. Created with 🤍💙 by <a href="https://www.softathlon.pl" style={{color: "#FA9513"}}> Softathlon</a>
                </p>
                v1.0.1
            </MaxWidthWrapper>
            </footer>
    );
}