import React from "react";
import { MaxWidthWrapper } from "../components/max-width-wrapper";
import { Contact } from "../components/contact";
import { Link } from "react-router-dom";
import { cn } from "../lib/utils";
import { buttonVariants } from "../components/ui/button";
import { UI_CONSTANTS } from "../ui-consts";
import image from "../images/ratusz_wb.png"; 
import { Navbar } from "../components/navbar";
import { Footer } from "../components/footer";

export default function HomePage() {
    return (
      <>
        <Navbar></Navbar>
        <main>
          <div
            className="hero bg-contain bg-no-repeat bg-right-bottom mb-12 mt-24 sm:mt:40"
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <div className="mx-auto mb-4 flex max-w-fit items-center justify-center space-x-2 overflow-hidden rounded-full border-gray-200 bg-white px-7 py-2 shadow-md backdrop-blur transition-all hover:border-gray-300 hover:bg-white/50">
        </div> */}
            <MaxWidthWrapper>
              <div className="w-full lg:w-10/12 flex flex-col items-center md:items-start justify-center md:justify-start">
                <h1 className="max-w-lg text-5xl md:text-6xl lg:text-7xl mb-8">
                  {UI_CONSTANTS.landingPage.header}
                  <span className="font-bold text-blue-700">
                    {UI_CONSTANTS.landingPage.headerColour}
                  </span>
                </h1>
                <p className="max-w-lg text-lg md:text-2xl lg:text-3xl mb-8 ">
                  {UI_CONSTANTS.landingPage.headerDescription}
                </p>
                <Link
                  to="/quiz"
                  className={cn(
                    buttonVariants({
                      variant: "default",
                      size: "xl",
                    }),
                    "uppercase text-lg md:text-md"
                  )}
                >
                  {UI_CONSTANTS.startQuiz}
                </Link>
              </div>
            </MaxWidthWrapper>
          </div>
          <Contact></Contact>
        </main>
        <Footer></Footer>
      </>
    );
}