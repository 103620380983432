import { QUESTIONS_COUNT } from "../../questions";
import { UI_CONSTANTS } from "../../ui-consts";

export const Statement = ({id,text}: {id:number,text:string}) => {

    return (
      <div className="p-10">
        <p>
          {UI_CONSTANTS.quiz.quizWithNo}
          {id}/{QUESTIONS_COUNT}
        </p>
        <p className="text-2xl text-center ">{text}</p>
      </div>
    );
}