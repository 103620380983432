export const COMMITEE_ANSWERS = [
    {
        commiteeId:1,
        answers: [
            {
              "id": 1,
              "answer": 0,
              "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit auctor."
            },
            {
              "id": 2,
              "answer": -1,
              "comment": "Suspendisse potenti. Nunc id ante eu lacus efficitur rhoncus."
            },
            {
              "id": 3,
              "answer": 1,
              "comment": "Vestibulum posuere libero id nisl consectetur, ut viverra justo cursus."
            },
            {
              "id": 4,
              "answer": -1,
              "comment": "Fusce ultricies ante ut mi venenatis tempus. Praesent vitae ligula urna."
            },
            {
              "id": 5,
              "answer": 1,
              "comment": "In hac habitasse platea dictumst. Etiam at semper urna."
            },
            {
              "id": 6,
              "answer": 0,
              "comment": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
            },
            {
              "id": 7,
              "answer": 1,
              "comment": "Proin convallis risus ut ullamcorper tincidunt. Sed non semper risus."
            },
            {
              "id": 8,
              "answer": -1,
              "comment": "Phasellus malesuada venenatis arcu, id ullamcorper orci malesuada ac."
            },
            {
              "id": 9,
              "answer": 0,
              "comment": "Quisque commodo ligula eu est feugiat, ac tincidunt massa tristique."
            },
            {
              "id": 10,
              "answer": 1,
              "comment": "Aliquam bibendum nulla et odio ultrices venenatis. Nulla facilisi."
            },
            {
              "id": 11,
              "answer": -1,
              "comment": "Cras venenatis vestibulum urna vitae dignissim. Integer eu nisi eu purus tempor ultricies."
            },
            {
              "id": 12,
              "answer": 0,
              "comment": "Vivamus nec libero sit amet nibh efficitur bibendum. Pellentesque euismod rutrum dui, eu imperdiet justo dignissim et."
            },
            {
              "id": 13,
              "answer": 1,
              "comment": "Nulla facilisi. Phasellus vel posuere libero, eu tempor mauris."
            },
            {
              "id": 14,
              "answer": 0,
              "comment": "Sed sit amet libero luctus, auctor nulla a, efficitur elit."
            },
            {
              "id": 15,
              "answer": 1,
              "comment": "Praesent tristique, justo a tincidunt tincidunt, neque elit pharetra lectus."
            },
            {
              "id": 16,
              "answer": -1,
              "comment": "Mauris ut arcu ut libero auctor fringilla vel vel ligula."
            },
            {
              "id": 17,
              "answer": 0,
              "comment": "Vestibulum fermentum odio nec laoreet ultricies. Pellentesque eleifend euismod."
            },
            {
              "id": 18,
              "answer": 1,
              "comment": "Sed bibendum ex nec mauris fringilla lacinia. Aenean tincidunt ex ut tellus lacinia, vel cursus nunc suscipit."
            },
            {
              "id": 19,
              "answer": -1,
              "comment": "Etiam vitae justo ut velit volutpat imperdiet. Morbi ut tortor vel enim lobortis vulputate."
            },
            {
              "id": 20,
              "answer": 1,
              "comment": "Aenean euismod neque non risus condimentum malesuada. Ut in tellus ac augue tincidunt convallis vel et odio."
            }
          ]
          
    },
    {
        commiteeId: 2,
        answers: [
            {
              "id": 1,
              "answer": 1,
              "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus."
            },
            {
              "id": 2,
              "answer": -1,
              "comment": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
            },
            {
              "id": 3,
              "answer": 0,
              "comment": "Fusce ultricies ante ut mi venenatis tempus. Integer iaculis urna vitae."
            },
            {
              "id": 4,
              "answer": 1,
              "comment": "Proin condimentum euismod elit et commodo. Quisque non mauris in massa."
            },
            {
              "id": 5,
              "answer": -1,
              "comment": "Cras venenatis vestibulum urna vitae dignissim. Vestibulum tristique tortor eu."
            },
            {
              "id": 6,
              "answer": 0,
              "comment": "Suspendisse potenti. Aliquam erat volutpat. Suspendisse potenti."
            },
            {
              "id": 7,
              "answer": 1,
              "comment": "In hac habitasse platea dictumst. Praesent tristique, justo a tincidunt tincidunt."
            },
            {
              "id": 8,
              "answer": -1,
              "comment": "Vivamus nec libero sit amet nibh efficitur bibendum. Nullam cursus vitae est."
            },
            {
              "id": 9,
              "answer": 0,
              "comment": "Nulla facilisi. Vestibulum fermentum odio nec laoreet ultricies. Nam vel."
            },
            {
              "id": 10,
              "answer": 1,
              "comment": "Etiam at semper urna. Vestibulum posuere libero id nisl consectetur, ut."
            },
            {
              "id": 11,
              "answer": 1,
              "comment": "Pellentesque euismod rutrum dui, eu imperdiet justo dignissim et. Nulla facilisi."
            },
            {
              "id": 12,
              "answer": 0,
              "comment": "Sed sit amet libero luctus, auctor nulla a, efficitur elit. Cras."
            },
            {
              "id": 13,
              "answer": -1,
              "comment": "Sed non semper risus. Quisque commodo ligula eu est feugiat, ac."
            },
            {
              "id": 14,
              "answer": 0,
              "comment": "Phasellus malesuada venenatis arcu, id ullamcorper orci malesuada ac. Sed bibendum."
            },
            {
              "id": 15,
              "answer": 1,
              "comment": "Phasellus vel posuere libero, eu tempor mauris. Aenean euismod neque non."
            },
            {
              "id": 16,
              "answer": -1,
              "comment": "Praesent vitae ligula urna. Vestibulum eleifend tortor eu metus mattis."
            },
            {
              "id": 17,
              "answer": -1,
              "comment": "Fusce ultricies ante ut mi venenatis tempus. Fusce sodales massa."
            },
            {
              "id": 18,
              "answer": 0,
              "comment": "Aliquam bibendum nulla et odio ultrices venenatis. Maecenas auctor, turpis vitae."
            },
            {
              "id": 19,
              "answer": 1,
              "comment": "Aenean euismod neque non risus condimentum malesuada. Integer eu nisi eu."
            },
            {
              "id": 20,
              "answer": -1,
              "comment": "Sed bibendum ex nec mauris fringilla lacinia. Quisque pulvinar laoreet felis."
            }
          ]          
    },
    {
        commiteeId: 3,
        answers: [
          {
            "id": 1,
            "answer": 1,
            "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus id quam nec justo."
          },
          {
            "id": 2,
            "answer": -1,
            "comment": "Suspendisse potenti. Nulla facilisi. Integer volutpat diam eu sapien."
          },
          {
            "id": 3,
            "answer": 0,
            "comment": "Vestibulum posuere libero id nisl consectetur, ut viverra justo cursus."
          },
          {
            "id": 4,
            "answer": 1,
            "comment": "Fusce ultricies ante ut mi venenatis tempus. Nullam dignissim, turpis in tincidunt."
          },
          {
            "id": 5,
            "answer": -1,
            "comment": "In hac habitasse platea dictumst. Curabitur hendrerit aliquam ligula, ac tristique odio."
          },
          {
            "id": 6,
            "answer": 0,
            "comment": "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
          },
          {
            "id": 7,
            "answer": 1,
            "comment": "Proin convallis risus ut ullamcorper tincidunt. Vivamus a volutpat nunc, in imperdiet ipsum."
          },
          {
            "id": 8,
            "answer": -1,
            "comment": "Phasellus malesuada venenatis arcu, id ullamcorper orci malesuada ac. Maecenas vel enim."
          },
          {
            "id": 9,
            "answer": 0,
            "comment": "Quisque commodo ligula eu est feugiat, ac tincidunt massa tristique. Nam nec mi."
          },
          {
            "id": 10,
            "answer": 1,
            "comment": "Aliquam bibendum nulla et odio ultrices venenatis. Proin suscipit a tortor."
          },
          {
            "id": 11,
            "answer": -1,
            "comment": "Cras venenatis vestibulum urna vitae dignissim. Suspendisse efficitur nunc at velit."
          },
          {
            "id": 12,
            "answer": 0,
            "comment": "Vivamus nec libero sit amet nibh efficitur bibendum. Pellentesque euismod rutrum dui."
          },
          {
            "id": 13,
            "answer": 1,
            "comment": "Nulla facilisi. Phasellus vel posuere libero, eu tempor mauris. Sed auctor venenatis."
          },
          {
            "id": 14,
            "answer": -1,
            "comment": "Sed sit amet libero luctus, auctor nulla a, efficitur elit. Sed laoreet vel."
          },
          {
            "id": 15,
            "answer": 0,
            "comment": "Praesent tristique, justo a tincidunt tincidunt, neque elit pharetra lectus. Morbi a eu."
          },
          {
            "id": 16,
            "answer": 1,
            "comment": "Mauris ut arcu ut libero auctor fringilla vel vel ligula. Vivamus nec eleifend."
          },
          {
            "id": 17,
            "answer": -1,
            "comment": "Vestibulum fermentum odio nec laoreet ultricies. Pellentesque eleifend euismod. Maecenas volutpat efficitur."
          },
          {
            "id": 18,
            "answer": 0,
            "comment": "Sed bibendum ex nec mauris fringilla lacinia. Aenean tincidunt ex ut tellus lacinia."
          },
          {
            "id": 19,
            "answer": 1,
            "comment": "Etiam vitae justo ut velit volutpat imperdiet. Morbi ut tortor vel enim lobortis vulputate."
          },
          {
            "id": 20,
            "answer": -1,
            "comment": "Aenean euismod neque non risus condimentum malesuada. Ut in tellus ac augue tincidunt convallis vel et odio."
          }
        ]
        
    }
];
