
import { COMMITEE_ANSWERS } from "../answers";
import { PostedAnswer } from "../models/posted-answer";
import { QuizScore } from "../models/quiz-score";
import { QUESTIONS_COUNT } from "../questions";


export function calculateResult(userAnswers: PostedAnswer[]){
    const committeeAnswers = COMMITEE_ANSWERS;
    const committees = COMMITEE_ANSWERS.map(x=>x.commiteeId);
    let commiteeScores = committees.map((c) => {
      return { committeeId: c, score: 0 };
    });
    userAnswers.forEach((ua)=>{
      const commiteAnswersForQuestion = committeeAnswers.map((ca) => {
        return {
          commiteeId: ca.commiteeId,
          answer: ca.answers.find((a) => a.id === ua.id),
        };
      });

      commiteAnswersForQuestion.forEach((ca) => {
        let indexToUpdate = commiteeScores.findIndex(
          (item) => item.committeeId === ca.commiteeId
        );
        let actualScore = commiteeScores.find(
          (score) => score.committeeId === ca.commiteeId
        )!.score;

        if (!ca.answer) {
          return;
        }

        if (
          (ca.answer.answer === 1 && ua.answer === 1) ||
          (ca.answer.answer === -1 && ua.answer === -1)
        ) {
          commiteeScores[indexToUpdate] = {
            committeeId: ca.commiteeId,
            score: actualScore + 3,
          };
          return;
        }
        if (ca.answer.answer === 0 && ua.answer === 0) {
          commiteeScores[indexToUpdate] = {
            committeeId: ca.commiteeId,
            score: actualScore + 2,
          };
          return;
        }
        if (
          (ca.answer.answer === 0 && ua.answer !== 0) ||
          (ca.answer.answer !== 0 && ua.answer === 0)
        ) {
          commiteeScores[indexToUpdate] = {
            committeeId: ca.commiteeId,
            score: actualScore + 1,
          };
          return;
        }
      });
    });

    return commiteeScores.map(score => { 
        return { 
            committeeId: score.committeeId, score: Math.round((score.score/(3*QUESTIONS_COUNT))*100)
        } as QuizScore
      }) ;
}